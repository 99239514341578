import { Pagination, Grid, Navigation } from "swiper/modules"
import { theme } from "tailwind.config"
import { CarouselProps } from "~/utils/carouselProps"

export const smOptionsGrid: CarouselProps = {
  slidesPerView: "auto",
  spaceBetween: 16,
  pagination: {
    enabled: true
  },
  grid: {
    rows: 2
  },
  modules: [Grid, Pagination],
  theme: "pagination-tiffany-tiffany30-grid"
}

export const smOptionsGridNoScrollbar: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 2,
  spaceBetween: 16,
  navigation: false,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30-bigger-padding"
}

export const mdOptionsGrid: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 6,
  navigation: true,
  pagination: {
    type: "progressbar"
  },
  modules: [Navigation, Pagination],
  theme: "navigation-dark-bottom-large-padding"
}

export const mdOptionsGridNoScrollbar: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 6,
  navigation: false,
  modules: [Navigation, Pagination]
}

export const smOptionsSmallCards: CarouselProps = {
  slidesPerView: "auto",
  breakpoints: {
    1024: {
      slidesPerView: "auto",
      slidesPerGroup: 6
    }
  }
}
