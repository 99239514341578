<script setup lang="ts">
import { PopularBrandsProps } from "./PopularBrands.props"
import {
  smOptionsGrid,
  smOptionsSmallCards,
  smOptionsGridNoScrollbar,
  mdOptionsGrid,
  mdOptionsGridNoScrollbar
} from "./PopularBrands.carouselOptions"
import { sm, md, lg } from "~/utils/breakpoints"

const props = withDefaults(defineProps<PopularBrandsProps>(), {
  hideScrollbar: false,
  hideSingleTab: false
})

const carouselOptions = computed(() =>
  props.hideScrollbar
    ? sm.value
      ? smOptionsGridNoScrollbar
      : mdOptionsGridNoScrollbar
    : sm.value
    ? smOptionsGrid
    : mdOptionsGrid
)

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium
  else if (props.bgImageLarge) return props.bgImageLarge
})
</script>

<template>
  <section
    class="
      carousel-container-overflowed
      padded
      relative
      overflow-x-hidden
      bg-tiffany-10
      py-6
    "
    :class="[{ 'overflow-y-hidden': oneRowCards }]"
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="wrapper-image-full-container-centered absolute inset-0 z-0"
    />
    <div class="relative">
      <p v-if="title" class="elephant-bold md:mammoth-bold mb:mb-6 mb-4">
        {{ title }}
      </p>

      <!-- Tabs -->
      <TheTabs
        v-if="tabs && !(tabs.tabsList.length == 1 && hideScrollbar)"
        v-bind="tabs"
        hide-bottom-border
        tab-layout="start"
      >
        <template #tabs="{ selectedTab }">
          <div
            v-for="(tab, i) in tabs.tabsList"
            v-show="selectedTab === i"
            class="
              -mx-4
              px-4
              pt-5
              md:-mx-[71px]
              md:px-[71px]
              lg:-mx-[228px]
              lg:px-[228px]
            "
          >
            <AppCarousel
              v-if="tab?.content"
              class="carousel-overflowed select-none"
              :swiper-props="carouselOptions"
              :items="tab.content"
              :theme="carouselOptions?.theme"
              :key="sm ? 1 : 0"
            >
              <template #default="{ item }">
                <BannerBrandCard class="swiper-tile" v-bind="item" />
              </template>
            </AppCarousel>
          </div>
        </template>
      </TheTabs>
      <div
        v-else-if="tabs?.tabsList && tabs?.tabsList.length == 1"
        class="
          -mx-4
          px-4
          py-5
          md:-mx-[71px]
          md:px-[71px]
          lg:-mx-[228px]
          lg:px-[228px]
        "
      >
        <AppCarousel
          v-if="tabs.tabsList[0].content"
          class="carousel-overflowed select-none"
          :swiper-props="carouselOptions"
          :items="tabs.tabsList[0].content"
          :theme="carouselOptions?.theme"
          :key="sm ? 1 : 0"
        >
          <template #default="{ item }">
            <BannerBrandCard class="swiper-tile" v-bind="item" />
          </template>
        </AppCarousel>
      </div>

      <!-- Single carousel -->
      <AppCarousel
        v-if="oneRowCards?.length"
        class="carousel-overflowed select-none"
        :swiper-props="smOptionsSmallCards"
        :items="oneRowCards"
        :theme="smOptionsSmallCards?.theme"
      >
        <template #default="{ item }">
          <BannerBrandCard class="swiper-tile" v-bind="item" />
        </template>
      </AppCarousel>
    </div>
  </section>
</template>

<style scoped lang="scss">

:deep(.the_tabs-labels) {
  // prettier-ignore
  @apply pig md:absolute md:right-0 md:top-0;
}

</style>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 6) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 6) - 24px);
  }
}

</style>
